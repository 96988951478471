<template>
  <div>
    <Menu />

    <div class="fixed-tags" style="background-color: #e00233; width: 40px; border-top-left-radius: 30px; border-bottom-left-radius: 30px;   position: fixed; top: 50%; right: 0; z-index: 1000;">
      <div class="container mt-2 mb-2">
        <div class="row">
          <div class="col-12 text-end">
            <a style="font-size: 20px; color: #fff;" href="https://www.facebook.com/chaseverittluxurywinelands" target="_blank">
              <i class="bi bi-facebook"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.linkedin.com/company/luxury-winelands-estate/about/" target="_blank">
              <i style="color: #fff;" class="bi bi-linkedin"></i>
            </a><br/>
            <a style="font-size: 20px; color: #fff;" href="https://www.instagram.com/chaseverittluxurywinelands/" target="_blank">
              <i style="color: #fff;" class="bi bi-instagram"></i>
            </a><br/>
          </div>
        </div>
      </div>
    </div>

    <section>
      <div class="container-fluid pt-3 g-0">
        <div class="row g-0">
          <div class="col-md-12 text-center">
            <div class="home-banner">
              <div class="col-lg-6 mx-auto align-self-center switch-white">
                <h1 class="display-2 mx-2"><strong>Chas Everitt - Luxury Winelands</strong> </h1>
                <h2 class="display-6 mx-2">Refined living in the heart of the Cape Winelands</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="container-fluid p-3 my-5">
        <div class="row g-0">
          <div class="col-md-12 text-center">
            <h2 class="pb-4"><strong>Find a property</strong></h2>
            <button class="btn cta-btn mx-1"><router-link to="/properties" @click.native="$store.state.searchState = 'Residential'; $store.state.filterType = 'Residential For Sale'; $store.state.filterProperty = 'House';" class="dropdown-item">Residential</router-link></button>
            <button class="btn cta-btn mx-1"><router-link to="/developments" class="dropdown-item">New Developments</router-link></button>
            <button class="btn cta-btn mx-1"><router-link to="/properties" @click.native="$store.state.searchState = 'Commercial'; $store.state.filterType = 'Commercial For Sale'; $store.state.filterProperty = 'Office';" class="dropdown-item">Commercial & Industrial</router-link></button>
            <button class="btn cta-btn mx-1"><router-link to="/properties" @click.native="$store.state.searchState = 'Farms'; $store.state.filterType = 'Commercial For Sale'; $store.state.filterProperty = 'Farm';" class="dropdown-item">Farms & Smallholdings</router-link></button>
          </div>
        </div>
      </div>
    </section>

    <section class="sectionOne">
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="col-md-8 col-lg-5 align-items-center">
            <div class="h-100 p-5" style="background-color: rgba(255, 255, 255, 0.5);">
              <h2 class="switch-blue"><strong>Welcome to the Winelands</strong></h2>
              <p>Journey through vineyard-dotted valleys and historic towns to discover South Africa's most coveted real estate. The Cape Winelands offers stunning scenery, world-class food and wine, a healthy lifestyle, top-tier education, and prestigious addresses. This luxury destination is where many aspire to call home.</p>
                <a href="https://www.youtube.com/watch?v=_M_LveiRIVI&t=1s" target="_blank" class="btn cta-btn">Watch video now</a><br/><br/>
                <a href="/winelands" class="btn cta-btn">Read more</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sectionTwo">
      <div class="container-fluid g-0">
        <div class="row g-0 switch-bg-lgrey my-5">
          <div class="col-lg-6">
            <img src="/images/home-luxuryestates.jpg" class="img img-fluid">
          </div>
          <div class="col-md-5 switch-bg-lgrey p-5 align-self-center">
            <div class="h-100 p-5">
              <h2 class="switch-blue mb-4"><strong>Luxury Estates</strong></h2>
              <p>Set in idyllic locations with wide-open spaces and tranquil environments, these luxury estates offer the
                perfect balance between fine living and a secure environment in which to raise a family or enjoy your
                retirement. </p>
              <p>Each uniquely designed estate complements the natural surroundings and provides you with the higher
                quality of life that you deserve. </p>
              <a href="/estates" class="btn cta-btn">Read more</a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <img src="/images/home-new.jpg" style="width: 100%;" class="d-block d-sm-none d-md-none">

    <section class="sectionThree">
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="col-md-1"></div>
          <div class="col-md-3 h-100">
            <div class="switch-bg-lgrey h-100 p-5">
              <h2 class="switch-blue mb-4"><strong>New Developments</strong></h2>
              <p>Few things excite us as much as breaking ground on a new development, where you find upmarket homes,
                state-of-the-art security, landscaped grounds, modern amenities, green technology and family-friendly
                facilities. </p>
              <p>These luxury new developments are architect-designed, with immaculate finishes, and enable discerning
                buyers and renters to live in their own piece of paradise. </p>
              <!-- <a href="/developments" class="btn cta-btn">Read more</a> -->
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="sectionFour">
      <div class="container-fluid g-0 pt-4">
        <div class="row g-0">
          <div class="col-1"></div>
          <div class="col-md-5 p-5 align-self-center">
            <div class="h-100 p-5 float-lg-end">
              <h2 class="switch-blue mb-4"><strong>Meet Our Team</strong></h2>
              <p>Say hello to the award-winning team that will expertly guide you on your property journey. </p>
              <p>With years of invaluable experience and the insights that come from living in the Winelands, this
                specialist team is passionate about providing each customer with the same exceptional service, so that
                your buying, selling, letting or renting experience is a seamless and memorable one.</p>
              <a href="/team" class="btn cta-btn">Read more</a>
            </div>
          </div>
          <div class="col-lg-6">
            <img src="images/home-team.jpg" class="img img-fluid">
          </div>
        </div>
      </div>
    </section>

    <!-- <FeaturedProperty /> -->

    <section class="sectionFive">
      <div class="container-fluid h-100">
        <div class="row h-100">
          <div class="col-12">
            <div class="text-center">
              <h2 class="switch-white mb-4">What our happy clients are saying about us</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <div id="carouselExampleAutoplaying" class="carousel carousel-dark slide" data-bs-ride="carousel">
          <div class="carousel-indicators">
            <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="0" class="active"
              aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide-to="1"
              aria-label="Slide 2"></button>
          </div>
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row">
                <div class="col-lg-4">
                  <div class="switch-bg-lblue p-4 switch-dblue h-100">
                    <p class="quote">"</p>
                    <p class="switch-dblue" style="height: 300px;">I've worked with the Chas Everitt team on many projects over the years. The way they deal with clients; their energy and passion for what they do, backed up by knowledge of the product they are selling, makes them the success they are. Chas Everitt is a formidable company to deal with.” </p>
                  </div>
                </div>
                <div class="col-lg-4 d-none d-md-block d-lg-block">
                  <div class="switch-bg-lblue p-4 switch-dblue h-100">
                    <p class="quote">"</p>
                    <p class="switch-dblue">I have had the pleasure of working with the Chas Everitt team for both home rental and property purchase and it was a great experience. The team is hands-on, responsive and goes above and beyond on every aspect of the process. They are my property go-to from now on. Keep up the great work.” </p>
                  </div>
                </div>
                <div class="col-lg-4 d-none d-md-block d-lg-block">
                  <div class="switch-bg-lblue p-4 switch-dblue h-100">
                    <p class="quote">"</p>
                    <p class="switch-dblue">I have now bought three homes from them in the past year and have never experienced more friendly professional service in all my life. I would not think of dealing with any other company after dealing with them. Just unbelievable!”</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row">
                <div class="col-lg-4">
                  <div class="switch-bg-lblue p-4 switch-dblue h-100">
                    <p class="quote">"</p>
                    <p class="switch-dblue" style="height: 300px;">There are no other agents that come close to the professionalism, integrity, and performance of Chas Everitt team. They are truly exceptional agents, matchmaking buyers with homes that meet their needs in Pearl Valley and Val De Vie.” </p>
                  </div>
                </div>
                <div class="col-lg-4 d-none d-md-block d-lg-block">
                  <div class="switch-bg-lblue p-4 switch-dblue h-100">
                    <p class="quote">"</p>
                    <p class="switch-dblue">The team from Chas Everitt are professional, extremely knowledgeable, hardworking, diligent and deliver against commitment. They build personal relationships and provide great communication throughout the process, including post the sale. They are seasoned in delivering against expectations and do this with great energy and great smiles.” </p>
                  </div>
                </div>
                <div class="col-lg-4 d-none d-md-block d-lg-block">
                  <div class="switch-bg-lblue p-4 switch-dblue h-100">
                    <p class="quote">"</p>
                    <p class="switch-dblue">It was only a pleasure working with the Chas Everitt team. As a first-time home buyer, they went the extra mile to ensure we fully understood the process. Our interests were well represented and we would highly recommend them for any home buyer or seller.” </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </div>
</template>
<script>

import Menu from '@/components/Menu.vue'
import FeaturedProperty from '@/components/FeaturedProperty.vue'
import Footer from '@/components/Footer.vue'
import Search from '@/components/Search.vue'

export default {
  components: {
    Menu, Footer, Search, FeaturedProperty
  },
  mounted() {
  },
  data() {
    return {
      data: {
        listings: []
      }
    }
  },
  methods: {

  }
}
</script>